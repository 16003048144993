
.navigation a button span{
    font-size: 16px !important;
}
.spaceBox{
    margin: 0 24px;
}
.pageTitle span{
    font-size: 24px !important;
}
.MuiCard-root{
    /* padding-bottom: 20px !important; */
    background: transparent;
}
a:focus {
    outline: none;
}
.css-koep9a {   
    font-size: 14px;
}
.table>tbody>tr>td {
    color: #384456;
}
.table-bordered>thead>tr>td, 
.table-bordered>thead>tr>th {
    border-bottom-width: 1px !important;
}
table.MuiTable-root{
    table-layout: unset !important;
}
.MuiTable-root tr td:first-child{
    width: 220px !important;
    padding-left: 20px !important;
}
.MuiTable-root tr th:first-child{
    width: 220px !important;
    padding-left: 20px !important;
}
.text-right {
    text-align: right;
}
/* .setwidth{
    width: 500px !important;;
} */
form.chat_box {
    margin-top: 30px;
}
.chat_box .media {
    display: flex;
    align-items: flex-start;
}
.chat_box .media img {
    margin-right: 10px;
}
.chat_box .media-body p {
    margin-top: 0;
}
.chat_box .media-body {
    width: 50%;
}
.media.media_admin {
    justify-content: flex-end;
    margin-right: 15px;
}
.chat_box_div {
    height: 400px;
    overflow-y: scroll;
    padding-bottom: 30px;
}
.chat_box .media.media_admin img {
    margin-left: 10px;
    margin-right: 0;
}
.chat_box .media-body .bg-light {
    background: #eee;
    padding: 8px;
    border-radius: 5px;
}
.chat_box .media-body .bg-primary {
    background: #eee;
    padding: 8px;
    border-radius: 5px;
}
.msg_send_form {
    position: relative;
}
.msg_send_form button {
    position: absolute;
    top: 2px;
    right: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 10px;
    background-color: #1976d2;
    color: #fff;
    border: 2px solid #1976d2;
    border-radius: 3px;
}
.msg_send_form button svg.icon {
    width: 15px;
    margin-right: 4px;
}
.message_section.messAge_recive {
    background: #eee;
    padding: 5px 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    width: 60%;
}
.message_section.message_send {
    background: #eee;
    padding: 5px 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    width: 60%;
    margin-left: auto;
}
.chat_message_section p {
    margin: 0 0 2px;
}
p.chat_time {
    font-size: 12px;
}
p.chat_name {
    color: #1976d2;
    font-size: 13px;
    font-weight: 600;
}
.chat_message_section {
    height: 650px;
    overflow-y: scroll;
    padding-bottom: 30px;
}
.dispute_send_msg input[type="text"] {
    width: 100%;
    height: 42px;
    padding: 10px;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
}
.dispute_send_msg {
    position: relative;
}
.dispute_send_msg input[type="file"] {
    opacity: 0;
    z-index: 1;
    position: relative;
    width: 50px;
    height: 40px;
}
.file-field {
    width: 50px;
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #1976d2;
    color: #fff;
    border-radius: 5px;
    margin-right: 3px;
    cursor: pointer;
}
.file_attach_icon {
    position: absolute;
    width: 20px;
    cursor: pointer;
    transform: rotate(40deg);
}
.dispute_send_btns {
    display: flex;
    align-items: center;
    position: absolute;
    top: 3px;
    right: 3px;
}
.dispute_send_btns button {
    z-index: 2;
}

.table-responsive {
  background: #f9f8fb;
  border-radius: 30px;
}
a {
    text-decoration: none !important;
}
.react-datepicker-wrapper,
.d-block {
    display: block !important;
}
.react-datepicker__day-name, 
.react-datepicker__day, 
.react-datepicker__time-name {    
    width: 35px !important;
}
.react-datepicker {
    font-size: 12px !important;
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {    
    font-size: 14px !important;
}

table tr:nth-last-child(1) .name,
table tr:nth-last-child(1) .Leadership,
table tr:nth-last-child(1) .Autopool{
    font-weight: 600;
}

.plan_pool{
  width: 3000px;
  margin-top: 40px;
  height: 100%;
}
.plan_pool img{
 z-index: 1;
 outline: 0;
}
.plan_pool_head {
 display: flex;
 align-items: center;
 justify-content: center;
 font-size: 56px;
 position: relative;
 margin: 10px auto 40px;
}

.plan_pool_head::after {
 content: "";
 background-color: #dfeffb;
 width: 1px;
 height: 57%;
 position: absolute;
 bottom: -40px;
}
.plan_pool_head_child {
 display: flex;
 align-items: center;
 justify-content: center;
 font-size: 16px;
 position: relative;
 margin: 29px 5px 0;
}
.plan_pool_first_row .plan_pool_head_child {
 width: 100px;
 height: 40px;
 font-size: 36px;
}
.plan_pool_first_row {
 display: flex;
 justify-content: space-around;
 position: relative;
}
.plan_pool_first_row::after {
 content: "";
 background-color: #dfeffb;
 width: 50%;
 
 width: 75%;
 height: 1px;
 position: absolute;
}
.plan_pool_head_child::before {
 content: "";
 background: #2a2a2a00 url("./assets/Tree/down_arw.png") no-repeat bottom;
 width: 11px;
 height: 75%;
 position: absolute;
 top: -29px;
}
.plan_pool_second_row .plan_pool_head_child {
 width: 32px;
 height: 45px;
 font-size: 30px;
 border-radius: 12px;
 margin-top: 26px;
}
.plan_pool_second_row > div {
 display: flex;
 justify-content: space-around;
 position: relative;
 margin-top: 30px;
 width: 50%;
 width: 22.5%;
}
.plan_pool_second_row {
 display: flex;
 justify-content: space-around;
}
.plan_pool_first_row .plan_pool_head_child::after{
 content: "";
 background-color: #dfeffb;
 width: 1px;
 height: 60%;
 position: absolute;
 bottom: -32px;
}
.plan_pool_second_row > div::before{
 content: "";
 background-color: #dfeffb;
 width: 75%;
 height: 1px;
 position: absolute;
}
.plan_pool_second_row .plan_pool_head_child::after{
  content: "";
  background-color: #dfeffb;
  width: 1px;
  height: 60%;
  position: absolute;
  bottom: -32px;
 }




 
.plan_pool_third_row {
  display: flex;
  justify-content: space-around;
}
 
.plan_pool_third_row .plan_pool_head_child::after { 
  width: 0px; 
} 
/* .plan_pool_third_row>div::before {
  content: "";
  background-color: #dfeffb;
  width: 50%;
  height: 1px;
  position: absolute;
}  */
.plan_pool_third_row >div{
  display: flex;
}
.plan_pool_third_row >div>div{
  display: flex;
}


.flx_div{
    display: flex;
    justify-content: space-between;
    width: 50%;
    align-items: center;
    flex-wrap: wrap;
}
.green{
    color: #00c160;
}
.react-tooltip {
    z-index: 100;
    background: #787a7b; 
}
.react-tooltip .tooltip_data{
    font-size: 16px;
}
.flexbox{
    flex-basis: 11%;
    padding-left: 10px !important;
    display: flex;
}
.css-1scr8os{
    display: none !important;
}
.style_card {
    color: #0068b3;
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 6%),0px 3px 4px 0px rgb(0 0 0 / 4%),0px 1px 8px 0px rgb(0 0 0 / 4%)!important;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 24px!important;
    background: linear-gradient(180deg,#e1f2fe,#fff)!important;
    border: 2px solid #dfeffb;
    box-shadow: none!important;
    border-radius: 20px;
}

